import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchMatchDetail,
  fetchPlayerWin,
  fetchMatchPlay,
  updateScore,
  createScore,
  updateMatchGift,
} from "./matchDetailAPI";

export const loadMatchDetail = createAsyncThunk(
  "matchDetailSlice/fetchMatchDetail",
  async (params, thunkAPI) => {
    const res = await fetchMatchDetail(params);
    return res;
  }
);
export const loadPlayerWin = createAsyncThunk(
  "matchDetailSlice/fetchPlayerWin",
  async (params, thunkAPI) => {
    const res = await fetchPlayerWin(params);
    const { total, data } = res;
    return { total, data };
  }
);
export const editScore = createAsyncThunk(
  "matchDetailSlice/updateScore",
  async (params, thunkAPI) => {
    const res = await updateScore(params.id, params.data);
    return res;
  }
);
export const addScore = createAsyncThunk(
  "matchDetailSlice/createScore",
  async (params, thunkAPI) => {
    const res = await createScore(params);
    return res;
  }
);
export const loadMatchPlay = createAsyncThunk(
  "matchDetailSlice/fetchMatchPlay",
  async (params, thunkAPI) => {
    const res = await fetchMatchPlay(params);
    const { total, data } = res;
    return { total, data };
  }
);
export const editMatchGift = createAsyncThunk(
  "matchDetailSlice/updateMatchGift",
  async (params, thunkAPI) => {
    const res = await updateMatchGift(params.id, params.data);
    return res;
  }
);

const matchDetailSlice = createSlice({
  name: "matchDetailSlice",
  initialState: {
    data: {},
    winerList: undefined,
    loading: false,
    loadingListWiner: false,
    loadingUpdateScore: false,
    loadingPlayList: false,
    playList: [],
    totalPlayList: 0,
    totalWiner: 0,
    error: "",
  },
  reducers: {
    clearWinList: (state, action) => {
      state.winerList = undefined;
    },
    clearData: (state) => {
      state.data = {};
    },
    updateMatchDetail: (state, action) => {
      state.data = action.payload;
    },
    updateWinerList: (state, action) => {
      state.winerList = action.payload.data;
      state.totalWiner = action.payload.total;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadMatchDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadMatchDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(loadMatchDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });

    builder.addCase(loadPlayerWin.pending, (state) => {
      state.loadingListWiner = true;
    });
    builder.addCase(loadPlayerWin.rejected, (state, action) => {
      state.loadingListWiner = false;
    });
    builder.addCase(loadPlayerWin.fulfilled, (state, action) => {
      state.loadingListWiner = false;
      state.winerList = action.payload.data;
      state.totalWiner = action.payload.total;
    });

    builder.addCase(loadMatchPlay.pending, (state) => {
      state.loadingPlayList = true;
    });
    builder.addCase(loadMatchPlay.rejected, (state, action) => {
      state.loadingPlayList = false;
    });
    builder.addCase(loadMatchPlay.fulfilled, (state, action) => {
      state.loadingPlayList = false;
      state.playList = action.payload.data;
      state.totalPlayList = action.payload.total;
    });

    builder.addCase(editScore.pending, (state) => {
      state.loadingUpdateScore = true;
    });
    builder.addCase(editScore.rejected, (state) => {
      state.loadingUpdateScore = false;
    });
    builder.addCase(editScore.fulfilled, (state, action) => {
      state.loadingUpdateScore = false;
      action.payload.type === 1
        ? (state.data.official_result = action.payload)
        : action.payload.type === 2
        ? (state.data.extra_result = action.payload)
        : (state.data.penalty_result = action.payload);
    });

    builder.addCase(addScore.fulfilled, (state, action) => {
      action.payload.type === 1
        ? (state.data.official_result = action.payload)
        : action.payload.type === 2
        ? (state.data.extra_result = action.payload)
        : (state.data.penalty_result = action.payload);
    });
  },
});

export const { clearWinList, updateMatchDetail, clearData, updateWinerList } =
  matchDetailSlice.actions;
export const selectMatchDetail = (state) => state.matchDetail;
export default matchDetailSlice.reducer;
