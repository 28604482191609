import axiosClient from "utils";

const fetchMatchDetail = async (id) => {
  const url = `/v1/matches/${id}`;
  return axiosClient.get(url);
};

const fetchPlayerWin = async (params) => {
  const url = `/v1/matches-predict-result/${params.id}`;
  delete params.id;
  return axiosClient.get(url, { params });
};

const fetchPlayerWinFinal = async (params) => {
  const url = `/v1/matches-predict-result-final/${params.id}`;
  delete params.id;
  return axiosClient.get(url, { params });
};

const updateConfirm = async (id, data) => {
  const url = `/v1/matches/${id}`;
  return axiosClient.put(url, data);
};

const confirmPlayerWin = async (id, data) => {
  const url = `/v1/matches-predict-result/${id}`;
  return axiosClient.put(url, data);
};

const cancelPlayerWin = async (id) => {
  const url = `/v1/matches-status/custom-prize/${id}`;
  return axiosClient.put(url);
};

const updateScore = async (id, data) => {
  const url = `/v1/match-results/${id}`;
  return axiosClient.put(url, data);
};

const createScore = async (data) => {
  const url = `/v1/match-results`;
  return axiosClient.post(url, data);
};

const fetchMatchPlay = async (params) => {
  const url = `v1/predicts`;
  return axiosClient.get(url, { params });
};

const fetchPrize = async (params) => {
  const url = `/v1/prizes`;
  return axiosClient.get(url, { params });
};

const fetchGift = async (params) => {
  const url = `/v1/gifts`;
  return axiosClient.get(url, { params });
};

const updateMatchGift = async (id, data) => {
  const url = `/v1/matches-gifts/${id}`;
  return axiosClient.put(url, data);
};

const fetchListPredictReal = async (id) => {
  const url = `/v1/matches-predict-result-fact/${id}`;
  return axiosClient.get(url);
};

const updateStatusPredict = async (id) => {
  const url = `/v1/matches-predict-result/status-predict/${id}`;
  return axiosClient.put(url);
};

const sendNotify = async (data) => {
  const url = `/v1/notify-match/now`;
  return axiosClient.post(url, data);
};

const fetchMessage = async (params) => {
  const url = `/v1/notify`;
  return axiosClient.get(url, { params });
};

export {
  updateConfirm,
  fetchMessage,
  sendNotify,
  updateStatusPredict,
  fetchListPredictReal,
  cancelPlayerWin,
  confirmPlayerWin,
  updateMatchGift,
  fetchGift,
  fetchPrize,
  fetchMatchDetail,
  fetchPlayerWin,
  updateScore,
  createScore,
  fetchMatchPlay,
  fetchPlayerWinFinal,
};
