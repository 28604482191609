import { List, Card, Badge, Row, Space, Tooltip } from "antd";
import numeral from "numeral";
import { DeleteTwoTone } from "@ant-design/icons";

import fallbackImage from "assets/fallbackImage.png";
import { typePrize } from "contants";

export default function MatchGift({
  gift,
  loading,
  onEditGift,
  onDeleteGift,
  editable = true,
}) {
  function renderPrize(id) {
    const item = typePrize.find((i) => i.type === id);
    return item ? { name: item.name, color: item.color } : null;
  }

  return (
    <List
      loading={loading}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: editable ? 3 : 4,
        xxl: 5,
      }}
      dataSource={gift}
      renderItem={(item) => (
        <List.Item style={{ display: "inline-block" }}>
          <Badge.Ribbon
            // text={renderPrize(item?.prize_info)?.name}
            // color={renderPrize(item?.prize_id)?.color}
            text={item?.prize_info?.name}
            color="#faad14"
            style={{ display: item?.prize_info ? "block" : "none" }}
          >
            <Card
              hoverable
              style={{ width: 240, height: 330 }}
              cover={
                <img
                  alt="gift"
                  src={
                    item?.gift_info?.image
                      ? `${process.env.REACT_APP_MEDIA_FILE}/${item?.gift_info?.image}`
                      : fallbackImage
                  }
                />
              }
            >
              <Card.Meta
                title={
                  <Row justify="space-between">
                    <span>{item?.gift_info?.name}</span>
                    <Space size={10}>
                      {/* <EditTwoTone onClick={() => onEditGift(item)} /> */}
                      {editable && (
                        <Tooltip title="Xóa giải" placement="bottom">
                          <DeleteTwoTone onClick={() => onDeleteGift(item)} />
                        </Tooltip>
                      )}
                    </Space>
                  </Row>
                }
                description={
                  <>
                    {item?.total && <span>Số lượng: {item.total}</span>}
                    <br />
                    {item?.gift_info?.value && (
                      <span>
                        Giá trị: {numeral(item?.gift_info?.value).format()}
                      </span>
                    )}
                  </>
                }
              />
            </Card>
          </Badge.Ribbon>
        </List.Item>
      )}
    />
  );
}
